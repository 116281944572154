import React, { Dispatch, SetStateAction, useRef } from 'react';

import {
  QueryLazyOptions,
  // eslint-disable-next-line no-restricted-imports
  useLazyQuery
} from '@apollo/client';

import ToastMessage from 'components/ToastMessage/ToastMessage';

import { useScope } from 'app/contexts/scopeProvider';

import { CONSECUTIVE_POLLING_ERROR_LIMIT } from 'app/global/variables';

import { GetFileUploadProgress, GetFileUploadProgressVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_FILE_UPLOAD_PROGRESS } from 'app/graphql/queries/getFileUploadProgress';

import useShowToast from 'app/hooks/useShowToast';

import { FileStatus, FileType, FileUploadResult } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

interface UseGetFileUploadProgressProps {
  fileUploadInProgress?: FileUploadResult[];
  setFileUploadInProgress?: Dispatch<SetStateAction<FileUploadResult[]>>;
  setFileUploadStatus?: Dispatch<SetStateAction<FileStatus>>;
  onRefetchRequested?: () => void;
  onDeleteRequested?: () => void;
}

interface UseGetFileUploadProgressReturn {
  pollForProcessingStatus: (options?: QueryLazyOptions<GetFileUploadProgressVariables>) => void;
  stopPolling: () => void;
}

export const useGetFileUploadProgress = ({
  fileUploadInProgress,
  setFileUploadInProgress,
  setFileUploadStatus,
  onRefetchRequested,
  onDeleteRequested
}: UseGetFileUploadProgressProps): UseGetFileUploadProgressReturn => {
  const showToast = useShowToast();
  const { selectedTenant } = useScope();
  const metadataFileIds = fileUploadInProgress.reduce((acc, file) => {
    acc = [...acc, file.tableId];
    return acc;
  }, []);

  const filterFileUploadInProgress = (completedFile) => {
    return fileUploadInProgress.filter((file) => completedFile.fileId !== file.tableId);
  };

  const consecutiveErrorCount = useRef(0);

  const [pollForProcessingStatus, { stopPolling, ...rest }] = useLazyQuery<
    GetFileUploadProgress,
    GetFileUploadProgressVariables
  >(GET_FILE_UPLOAD_PROGRESS, {
    variables: {
      fileIds: metadataFileIds
    },
    fetchPolicy: 'network-only',
    pollInterval: 2000,
    notifyOnNetworkStatusChange: true,
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors?.[0]?.message?.includes('not found')) {
        const fileIdPattern = /file\s([^\s]+)\snot\sfound/;
        const match = fileIdPattern.exec(graphQLErrors[0].message);
        const fileId = match?.[1];
        const tableDeletionInProgress = fileUploadInProgress.find(
          (file) =>
            file.tableId === fileId && (file.status === FileStatus.PRE_DELETION || file.status === FileStatus.DELETING)
        );
        if (fileId && tableDeletionInProgress) {
          showToast(
            <ToastMessage
              title={formatMessage('DELETE_TABLE_SUCCESS_TITLE')}
              message={formatMessage('DELETE_TABLE_SUCCESS_MESSAGE', { tableName: tableDeletionInProgress.tableName })}
            />,
            'success'
          );
          const newFileUploadInProgress = fileUploadInProgress.filter(
            (file) => file.tableId !== tableDeletionInProgress.tableId
          );
          setFileUploadInProgress(newFileUploadInProgress);
          onDeleteRequested?.();
          onRefetchRequested?.();
        }
      }
      handleError(graphQLErrors, networkError);

      if (!selectedTenant || consecutiveErrorCount.current >= CONSECUTIVE_POLLING_ERROR_LIMIT) {
        return stopPolling();
      }
      consecutiveErrorCount.current += 1;
    },
    onCompleted(data) {
      const { getFileUploadProgress } = data;
      if (getFileUploadProgress.length === 0 && fileUploadInProgress.length === 0) {
        consecutiveErrorCount.current = 0;
        onDeleteRequested?.();
        return stopPolling();
      }

      // When there are multiple files in progress and any file has been deleted
      if (getFileUploadProgress.length < fileUploadInProgress.length) {
        // Check for deleted file
        // If a file is in fileUploadInProgress with pre-deletion or deleting status but not in the response, the file has been successfully deleted
        for (const file of fileUploadInProgress) {
          if (file.status === FileStatus.DELETING || file.status === FileStatus.PRE_DELETION) {
            // remove the file from fileUploadInProgress to stop polling status for this file
            file.fileId = file.tableId;
            setFileUploadInProgress(filterFileUploadInProgress(file));
            // show success toast for this file
            showToast(
              <ToastMessage
                title={formatMessage('DELETE_TABLE_SUCCESS_TITLE')}
                message={formatMessage('DELETE_TABLE_SUCCESS_MESSAGE', { tableName: file.tableName })}
              />,
              'success'
            );
            // refetch to show that the file is gone
            onRefetchRequested?.();
            // call methods that need to be executed on deleting eg. close the dialog in case it does not reach to the deleting state
            onDeleteRequested?.();
          }
        }
        return stopPolling();
      }
      // Check whether processing status has changed yet to completed or failed
      // status could be 'pending' and we let it poll until we find a
      // 'completed' or 'failed' status
      getFileUploadProgress.forEach((completedFile) => {
        // If this file is in fileUploadInProgress but in pre-deletion status,
        // it means that a deletion starts but the status has not been set to deleting
        // Do nothing and wait for the next poll
        const isFileInPreDeletion = fileUploadInProgress.some(
          (file) => file.tableId === completedFile.fileId && file.status === FileStatus.PRE_DELETION
        );
        // Update the state when the status changes
        if (completedFile.status === FileStatus.IN_PROGRESS || completedFile.status === FileStatus.VALIDATING) {
          if (completedFile.fileType !== FileType.ACTIVITY)
            setFileUploadStatus((prev) => {
              if (prev === completedFile.status) {
                return prev;
              }
              return completedFile.status as FileStatus;
            });
        }
        if (completedFile.status === FileStatus.COMPLETED) {
          // Otherwise, it is a file uploaded successfully
          // show success toast and remove it from fileUploadInProgress to stop polling for this file
          if (!isFileInPreDeletion) {
            setFileUploadInProgress(filterFileUploadInProgress(completedFile));
            setFileUploadStatus(null);
            showToast(
              <ToastMessage
                title={formatMessage('FILE_HAS_BEEN_PUBLISH_SUCCESS_TITLE')}
                message={formatMessage('FILE_HAS_BEEN_PUBLISH_SUCCESS', { name: completedFile.fileName })}
              />,
              'success'
            );
          }
        }
        if (completedFile.status === FileStatus.FAILED) {
          if (!isFileInPreDeletion) {
            setFileUploadInProgress(filterFileUploadInProgress(completedFile));
            setFileUploadStatus(null);
            showToast(
              <ToastMessage
                title={formatMessage('FILE_IMPORT_FAILED')}
                message={formatMessage('FILE_HAS_BEEN_PUBLISH_ERROR', { name: completedFile.fileName })}
              />,
              'danger'
            );
          }
        }
        if (completedFile.status === FileStatus.DELETING) {
          // If a deleting status is found, update the pre-deletion status, show in-progress toast and refresh the grid
          const tablePreDeletionInProgress = fileUploadInProgress.find(
            (file) => file.tableId === completedFile.fileId && file.status === FileStatus.PRE_DELETION
          );
          if (tablePreDeletionInProgress) {
            onDeleteRequested?.();
            showToast(
              <ToastMessage
                title={formatMessage('DELETE_TABLE_IN_PROGRESS')}
                message={formatMessage('DELETE_TABLE_IN_PROGRESS_MESSAGE', { tableName: completedFile.fileName })}
              />,
              'warning'
            );
            const fileUploadInProgressWithRemovedPreDeletion = fileUploadInProgress.filter(
              (file) => file.tableId !== tablePreDeletionInProgress.tableId
            );
            fileUploadInProgressWithRemovedPreDeletion.push({
              ...tablePreDeletionInProgress,
              status: FileStatus.DELETING
            });
            setFileUploadInProgress(fileUploadInProgressWithRemovedPreDeletion);
            onRefetchRequested?.();
          }
        }
      });
    }
  });
  return { pollForProcessingStatus, stopPolling, ...rest };
};
